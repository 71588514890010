import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import WishlistContext from '../../context/WishlistProvider';
import CartContext from '../../context/CartProvider';

import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';
import ProductGrid from '../../components/organisms/ProductGrid/ProductGrid';
import ConfirmDialog from '../../components/atoms/ConfirmDialog/ConfirmDialog';
import * as styles from './wishlists.module.css';

const AccountWishlists = () => {
  const wishlistContext = useContext(WishlistContext);
  const cartContext = useContext(CartContext);
  const addAllToCart = cartContext && cartContext.addAllToCart;
  const { getAccountWishlists, getWishlistItems } = wishlistContext || {};

  const [wishlistsData, setWishlistsData] = useState({
    wishlists: [],
    wishlistsFetching: false,
    wishlistsFetched: false,
    activeWishlist: -1,
    wishlistItems: [],
    wishlistProducts: [],
    activeEditor: -1,
    wishlistName: '',
    updateInProgress: false
  });

  const [newWishlistName, setNewWishlistName] = useState('');
  const [showNewWishlist, toggleShowNewWishlist] = useState(false);

  const viewWishlist = (id, items) => {
    getWishlistItems(items, 300).then(wishlistProducts => {
      setWishlistsData({
        ...wishlistsData,
        ...{ activeWishlist: id, wishlistItems: items, wishlistProducts }
      });
    });
  };

  const editWishlist = (editor, name) => {
    if (wishlistsData.activeEditor !== editor) {
      setWishlistsData({ ...wishlistsData, ...{ activeEditor: editor, wishlistName: name } });
    } else {
      setWishlistsData({ ...wishlistsData, ...{ activeEditor: -1, wishlistName: '' } });
    }
  };

  const addNewWishlist = () => {
    if (newWishlistName) {
      wishlistContext
        .saveWishlist(newWishlistName)
        .then(({ response, status }) => {
          if (response.data && status === 201) {
            setWishlistsData({
              ...wishlistsData,
              ...{
                wishlists: [...wishlistsData.wishlists, response.data]
              }
            });
          }
          setNewWishlistName('');
          toggleShowNewWishlist(false);
        });
    }
  };

  const updateWishlist = (wishlistIndex, wishlistId, wishlistItems) => () => {
    setWishlistsData({ ...wishlistsData, ...{ updateInProgress: true } });
    wishlistContext
      .updateAccountWishlist(
        wishlistId,
        wishlistsData.wishlistName,
        wishlistItems
      )
      .then(response => {
        if (response.data) {
          const data = wishlistsData.wishlists;
          data[wishlistIndex].name = response.data.name;
          setWishlistsData({
            ...wishlistsData,
            ...{
              wishlists: data,
              wishlistName: '',
              activeEditor: -1,
              updateInProgress: false
            }
          });
        }
      });
  };

  const removeWishlist = wishlistId => {
    wishlistContext.removeWishlist(wishlistId).then(() => {
      const _wishlists = wishlistsData.wishlists;
      let deleteIndex = -1;
      _wishlists.map((wishlist, wishlistIndex) => {
        if (wishlist.id === wishlistId) {
          deleteIndex = wishlistIndex;
        }

        return true;
      });
      _wishlists.splice(deleteIndex, 1);
      setWishlistsData({
        ...wishlistsData,
        ...{
          activeWishlist: -1,
          wishlistItems: [],
          wishlistProducts: [],
          wishlists: _wishlists
        }
      });
    });
  };

  const removeWishlistItem = (productId, variantId) => {
    wishlistContext
      .removeFromAccountWishlist(
        wishlistsData.activeWishlist,
        wishlistsData.wishlistItems,
        null,
        productId,
        variantId
      )
      .then(newList => {
        if (newList.length > 0) {
          wishlistContext
            .getWishlistItems(newList, 300)
            .then(wishlistProducts => {
              setWishlistsData({
                ...wishlistsData,
                ...{ wishlistItems: newList, wishlistProducts }
              });
            });
        } else {
          // if newList length is zero, delete list
          removeWishlist(wishlistsData.activeWishlist);
        }
      });
  };

  const updateWishlistName = e => {
    setWishlistsData({
      ...wishlistsData,
      ...{ wishlistName: e.target.value }
    });
  };

  const items = useMemo(() => {
    if (wishlistsData.wishlists) {
      return wishlistsData.wishlists;
    }
    return [];
  }, [wishlistsData]);

  useEffect(() => {
    if (
      !wishlistsData.wishlistsFetched &&
      !wishlistsData.wishlistsFetching &&
      typeof getAccountWishlists === 'function'
    ) {
      setWishlistsData({ ...wishlistsData, ...{ wishlistsFetching: true } });
      getAccountWishlists().then(wishlistsAPIData => {
        if (wishlistsAPIData.data) {
          setWishlistsData({
            ...wishlistsData,
            ...{
              wishlists: wishlistsAPIData.data,
              wishlistsFetching: false,
              wishlistsFetched: true
            }
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [wishlistsData]);

  return (
    <div>
      {!isEmpty(items) && (
        <div className="dataTable mb-4">
          <div className={styles.wishlistHead}>
            <div>
              <span>Wishlist Name</span>
            </div>
            <div>
              <span>Items</span>
            </div>
            <div>
              <span>Shared</span>
            </div>
          </div>
          {items.map((wishlist, wishlistIndex) => {
            const actived = wishlistIndex === wishlistsData.activeEditor;
            return (
              <div className={styles.wishlistItem} key={wishlist.id}>
                <div className={styles.wishlistRow}>
                  <div>
                    <span
                      role="presentation"
                      data-button
                      onClick={() => viewWishlist(wishlist.id, wishlist.items)}
                    >
                      {wishlist.name}
                    </span>
                  </div>
                  <div>
                    <span>{wishlist?.items?.length}</span>
                  </div>
                  <div>
                    <span>{wishlist?.is_public ? 'Yes' : 'No'}</span>
                  </div>
                  <div>
                    <span
                      role="presentation"
                      data-button
                      className="icon-wrap"
                      onClick={() => viewWishlist(wishlist.id, wishlist.items)}
                    >
                      View
                    </span>
                    <span
                      role="presentation"
                      data-button
                      className="icon-wrap"
                      onClick={() => editWishlist(wishlistIndex, wishlist.name)}
                    >
                      {actived ? 'Cancel' : 'Edit'}
                    </span>
                    <ConfirmDialog
                      title="Are you sure?"
                      message="Are you sure you want to remove this wishlist? It will not be recoverable after you proceed."
                      onOk={() => removeWishlist(wishlist.id)}
                    >
                      <span data-button className="icon-wrap">
                        Delete
                      </span>
                    </ConfirmDialog>
                  </div>
                </div>
                <div
                  className={clsx(
                    styles.wishlistItemEditor,
                    actived && styles.active
                  )}
                >
                  <div className="formField">
                    <input type="text" defaultValue={wishlistsData.wishlistName} onChange={updateWishlistName} />
                  </div>
                  <div className="formField">
                    <Button
                      level="primary"
                      size="small"
                      type="button"
                      disabled={wishlistsData.updateInProgress}
                      onClick={updateWishlist(
                        wishlistIndex,
                        wishlist.id,
                        wishlist.items
                      )}
                    >
                      {actived && wishlistsData.updateInProgress && (
                        <span>Updating...</span>
                      )}
                      {!wishlistsData.updateInProgress && <span>Update</span>}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {wishlistsData.wishlistProducts.length > 0 && (
        <>
          <ConfirmDialog
            message="Would you also like to clear this wishlist from your account?"
            btnOk="Yes"
            onOk={() => {
              addAllToCart(wishlistsData.wishlistItems);
              removeWishlist(wishlistsData.activeWishlist);
            }}
            btnCancel="No"
            onCancel={() => addAllToCart(wishlistsData.wishlistItems)}
          >
            <Button
              level="primary"
              type="button"
            >
              Add all items to cart
            </Button>
          </ConfirmDialog>
          <ProductGrid
            products={wishlistsData.wishlistProducts}
            removeFunction={removeWishlistItem}
          />
        </>
      )}
      {wishlistsData.wishlistsFetched && items.length === 0 && (
        <div>
          You have no wishlists. Build your first list by adding products using
          the <Icon symbol="heart" /> icon, then click "Save Wishlist" via the
          section in the header.
        </div>
      )}

      {!wishlistsData.wishlistsFetched && (
        <span>Fetching your wishlists...</span>
      )}

      <div className={`formField ${showNewWishlist ? 'show' : 'hidden'}`}>
        <input
          type="text"
          defaultValue={newWishlistName}
          onChange={e => setNewWishlistName(e.target.value)}
        />
      </div>
      {wishlistsData.wishlistsFetched && (
        <div className={`flex-center mt-5 ${styles.actions}`}>
          <Button
            level="primary"
            type="button"
            onClick={
              showNewWishlist
                ? addNewWishlist
                : () => toggleShowNewWishlist(true)
            }
          >
            {showNewWishlist ? 'Save Wishlist' : 'New Wishlist'}
          </Button>

          {showNewWishlist && (
            <Button
              level="primary"
              type="button"
              className="ml-4 show"
              onClick={() => toggleShowNewWishlist(false)}
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const AccountWishlistsOutput = () => {
  return (
    <AccountPageWrapper metaTitle="Account - Wishlists" title="Wishlists">
      <AccountWishlists />
    </AccountPageWrapper>
  );
};
export default AccountWishlistsOutput;
