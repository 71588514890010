import React, { useContext, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../atoms/Button/Button';
import * as styles from './ConfirmDialog.module.css';
import ContentfulContext from '../../../context/ContentfulProvider';
import { get } from 'lodash';

const ConfirmDialog = ({children, title, message, onOk, onCancel, btnOk, btnCancel, disableBackdropClick = false}) => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(true)
    }

    const handleCancel = (event, reason) => {
        if (disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (onCancel) {
            onCancel()
        }
        setOpen(false)
    }

    const handleOk = () => {
        if (onOk) {
            onOk()
        }
        setOpen(false)
    }

    return (
        <>
            <span role="presentation" onClick={handleClick}>{children}</span>
            <Dialog
                disableEscapeKeyDown
                keepMounted
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={open}
                onClose={(event, reason) => handleCancel(event, reason)}
                >
                <DialogTitle id="confirmation-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    {message}
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button onClick={handleCancel} level="secondary" size="small" type="span">
                    {btnCancel || get(translations, 'cancel.title', 'Cancel')}
                    </Button>
                    <Button onClick={handleOk} level="primary" size="small" type="span">
                    {btnOk || 'Ok'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDialog